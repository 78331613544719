let oldProperties = [{
    address: '6013 San Bernard',
    isSold: true,
    salePrice: '$650,000',
    lists: [{
        title: 'Features',
        items: [{
            text: 'Download Flyer',
            url: 'flyer.pdf'
        }, 
        '3 Bedrooms', 
        '2 Bath', 
        'Open Living', 
        'Dining & Kitchen plus Flexroom'
        ]
    }],
    gallery: [
        'front',
        '6013-San-Bernard-Plan'
    ]
},{
    address: '6107 Lampasas Court',
    isSold: true,
    salePrice: '$550,000',
    lists: [{
        title: 'Features',
        items: [{
            text: 'Download Flyer',
            url: 'flyer.pdf'
        }, 
        '4 Bedrooms',
        '2 Bathrooms',
        'Open Living',
        'Oversized 2 Car Garage',
        'Cul-de-sac',
        'Family-friendly neighborhood with community pool, clubhouse, & playground',
        'April 2023 Completion',
        ]
    }],
    gallery: [
        'front'
    ]
},{
    address: '5809 Val Verde',
    isSold: true,
    salePrice: '$530,000',
    lists: [{
        title: 'Features',
        items: [{
            text: 'Download Flyer',
            url: 'flyer.pdf'
        }, 
        '3 Bedrooms',
        '2.5 Bathrooms',
        'Flex Room / Office',
        'Open Living',
        'Oversized 2 Car Garage',
        'Pool – sized backyard',
        'Prestigious neighborhood with community pool, clubhouse, splash pad, playground, pond & walking trails',
        ]
    }],
    gallery: [
        'front'
    ]
},{
    address: '6101 Lampasas Court',
    isSold: true,
    salePrice: '$515,000',
    lists: [{
        title: 'Features',
        items: [{
            text: 'Download Flyer',
            url: 'flyer.png'
        }, 
        '4 Bedrooms', 
        '2.5 Bathrooms', 
        'Open Living', 
        '3 Car Tandem Garage',
        'Corner Cul-de-sac Lot'
        ]
    }],
    gallery: [
        'front'
    ]
},{
    address: '6305 Woodrose',
    isSold: true,
    salePrice: '0',
    lists: [{
        title: '2020 Parade of Homes Winner',
        items: [
            'Best in Show',
            'Favorite Kitchen',
            'Best Interior Design',
            'Best Curb Appeal',
            'Favorite Outdoor Living',
        ]
    }],
    gallery: [
        'front',
        '6305-woodrose-1',
        '6305-woodrose-2',
        '6305-woodrose-3',
        '6305-woodrose-4',
        '6305-woodrose-5',
        '6305-woodrose-6',
        '6305-woodrose-7',
        '6305-woodrose-8',
        '6305-woodrose-9',
        '6305-woodrose-10',
        '6305-woodrose-11',
        '6305-woodrose-12',
        '6305-woodrose-13',
        '6305-woodrose-14',
        '6305-woodrose-15',
        '6305-woodrose-16',
        '6305-woodrose-17',
        '6305-woodrose-18',
        '6305-woodrose-19',
        '6305-woodrose-20',
        '6305-woodrose-21',
        '6305-woodrose-22',
        '6305-woodrose-23',
        '6305-woodrose-24',
        '6305-woodrose-25',
        '6305-woodrose-26',
        '6305-woodrose-27',
        '6305-woodrose-28',
        '6305-woodrose-29',
        '6305-woodrose-30',
        '6305-woodrose-31',
        '6305-woodrose-32',
        '6305-woodrose-33',
        '6305-woodrose-34',
        '6305-woodrose-35',
        '6305-woodrose-36',
        '6305-woodrose-37',
        '6305-woodrose-38',
        '6305-woodrose-39',
        '6305-woodrose-40',
        '6305-woodrose-41',
        '6305-woodrose-42',
        '6305-woodrose-43',
        '6305-woodrose-44',
        '6305-woodrose-45',
        '6305-woodrose-46',
        '6305-woodrose-47',
        '6305-woodrose-48',
        '6305-woodrose-49',
        '6305-woodrose-50',
        '6305-woodrose-51',
        '6305-woodrose-52',
        '6305-woodrose-53',
        '6305-woodrose-54',
        '6305-woodrose-55',
        '6305-woodrose-56',
        '6305-woodrose-57',
        '6305-woodrose-58',
        '6305-woodrose-59',
        '6305-woodrose-60'

    ]
},{
    address: '6003 San Bernard',
    isSold: true,
    salePrice: '$480,000',
    lists: [{
        title: 'Features',
        items: [{
            text: 'Download Flyer',
            url: 'flyer.pdf'
        }, 
        '4 Bedrooms', 
        '2.5 Bathrooms', 
        'Open Living', 
        '3 Car Tandem Garage'
        ]
    }],
    gallery: [
        'front'
    ]
},{
    address: '4412 Roadrunner Trail',
    isSold: true,
    salePrice: '$775,000',
    lists: [{
        title: 'Features',
        items: [{
            text: 'Download Flyer',
            url: 'flyer.pdf'
        }, 
        '4 Bedrooms', 
        '3 Bathrooms', 
        '1.1 Acre Lot', 
        '3 Car Tandem Garage',
        'Gameroom'
        ]
    }],
    gallery: [
        'front'
    ]
}];


module.exports = [{
    address: '4503 Clear Fork Court',
    isSold: true,
    salePrice: '$607,000',
    lists: [{
        title: 'Features',
        items: [{
            text: 'Download Flyer',
            url: 'flyer.pdf'
        },
        'Heritage Oaks',
        '4 Bedrooms',
        '2.5 Bathrooms',
        'Open Living',
        '3 Car Tandem Garage',
        'Cul-de-sac',
        'Family-friendly neighborhood with community pool, clubhouse, & playground',
        'August 2024 Completion',
        ]
    }]
},{
    address: '4507 Clear Fork Court',
    isSold: true,
    salePrice: '$610,000',
    lists: [{
        title: 'Features',
        items: [{
            text: 'Download Flyer',
            url: 'flyer.pdf'
        },
        'Heritage Oaks',
        '4 Bedrooms',
        '3 Bathrooms',
        '3 Car Tandem Garage',
        'Oversized Pantry',
        'Cul-de-sac',
        'Family-friendly neighborhood with community pool, clubhouse, & playground',
        'August 2024 Completion',
        ]
    }]
},{
    address: '4501 Bosque',
    isSold: true,
    salePrice: '$690,000',
    lists: [{
        title: 'Features',
        items: [{
            text: 'Download Flyer',
            url: 'flyer.pdf'
        },
        'Heritage Oaks',
        '4 Bedrooms + Flex Room',
        '3 Bathrooms',
        '3 Car Tandem Garage',
        'Giant Pantry',
        'Oversized Corner Lot on a Cul-de-sac',
        'Family-friendly neighborhood with community pool, clubhouse, & playground',
        'Spring 2025 Completion',
        ]
    }]
},{
    address: '4504 Clear Fork Court',
    isSold: false,
    salePrice: '$610,000',
    lists: [{
        title: 'Features',
        items: [{
            text: 'Download Flyer',
            url: 'flyer.pdf'
        },
        'Heritage Oaks',
        '4 Bedrooms',
        '3 Bathrooms',
        '3 Car Tandem Garage',
        'Oversized Pantry',
        'Cul-de-sac',
        'Family-friendly neighborhood with community pool, clubhouse, & playground',
        'Spring 2025 Completion',
        ]
    }]
},];